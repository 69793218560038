$(document).ready(function () {
  const form = $('#form-debt')
  form.submit(function(e){
    e.preventDefault();
    mail()
  })

  function mail() {
    let formData = new FormData()
    formData.append('lastname', $('#lastname').val())
    formData.append('name', $('#name').val())
    formData.append('patronymic', $('#patronymic').val())
    formData.append('cadastral', $('#cadastral').val())
    formData.append('number', $('#number').val())
    formData.append('settlement', $('#settlement').val())
    formData.append('email', $('#email').val())
  
    $.ajax({
      method: 'POST',
      url: '/php/mail.php',
      contentType: false,
      processData: false,
      dataType: 'json',
      data: formData,
      chache: false,
      beforeSend: function () {
        form.find('input').prop('disabled', true)
        form.find('select').prop('disabled', true)
        $('.modal__loader').addClass('modal__loader--show')
      },
      success: function (data) {
        if (data.status == 'success') {
          $('#lastname').val('')
          $('#lastname').text('')
          $('#name').val('')
          $('#name').text('')
          $('#patronymic').val('')
          $('#patronymic').text('')
          $('#cadastral').val('')
          $('#cadastral').text('')
          $('#number').val('')
          $('#number').text('')
          $('#settlement').val('none')
          $('#email').val('')
          $('#email').text('')
        }
        else if (data.status == 'error') {
          $('.modal__loader').removeClass('modal__loader--show')
          $('.modal__form').html('<p>Заявку отправить <b>не удалось</b>!<br>Попробуйте позжею</p>')
        }
      },
      error: function (jqxhr, status, errorMsg) {
        $('.modal__loader').removeClass('modal__loader--show')
        $('.modal__form').html('<p>Заявку отправить <b>не удалось</b>!<br>Попробуйте позжею</p>')
      },
      complete: function () {
        $('.modal__loader').removeClass('modal__loader--show')
        form.find('input').prop('disabled', false)
        form.find('select').prop('disabled', false)
        $('.modal__form').html("<p>Ваша заявка <b>отправлена.</b><br>Ответ придет на указанный Вами адрес электронной почты.</p>")
      },
    })
  }
})
